import { Component, Input, OnInit } from '@angular/core';
import { L } from '@ic2/ic2-lib';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService, FeedbackDTO, FeedbackReaction, FeedbackReactionDTO, FeedbackRecipientType, FeedbackService, FeedbackUserDTO, HijiBundle } from 'common';
import { FeedbackUserListModalComponent } from '../feedback-user-list-modal/feedback-user-list-modal.component';

@Component({
  selector: 'hiji-feedback-message',
  templateUrl: './feedback-message.component.html',
  styleUrls: ['./feedback-message.component.scss'],
})
export class FeedbackMessageComponent implements OnInit {
  idUserLogged: number;
  @Input() feedback: FeedbackDTO;
  FeedbackReaction: typeof FeedbackReaction = FeedbackReaction;
  FeedbackRecipientType: typeof FeedbackRecipientType = FeedbackRecipientType;
  displayFunctionFront: boolean = false;

  constructor(
    private authService: AuthService,
    private feedbackService: FeedbackService,
    private modalService: NgbModal
  ) {
    this.idUserLogged = this.authService.userBundle.user.idUser;
  }

  ngOnInit(): void {
    this.displayFunctionFront = (<HijiBundle>this.authService.userBundle.data).modules.frontConfiguration.displayFunctionFront;
  }

  react(reaction: FeedbackReaction) {
    if (this.authService.userBundle.user.idUser === this.feedback.feedback.idUserSender) {
      //c'est mon feedback
      //OPEN MODAL pour reaction
      const reactions = this.feedback.reactions.filter((r) => r.reaction === reaction);
      this.openModal(reactions);
    } else {
      let removeReaction = false;
      if (this.feedback.reactions.find((r) => r.idUser === this.idUserLogged && r.reaction === reaction) !== undefined) removeReaction = true;
      //C'est pas mon feedback
      this.feedbackService
        .react(this.feedback.feedback.idFeedback, removeReaction ? null : reaction)
        .onBusinessErrorCode('already_reacted', (err) => {
          console.error(err);
        })
        .onOtherErrorTypesUseDefault()
        .execute((data) => {
          //Si j'était déjà dans la liste des reactions, j'update , sinon j'ajoute
          let maReaction = this.feedback.reactions.find((r) => r.idUser === this.idUserLogged);
          if (maReaction === undefined) {
            maReaction = new FeedbackReactionDTO();
            maReaction.reaction = reaction;
            maReaction.idUser = this.idUserLogged;
            maReaction.firstName = this.authService.userBundle.user.firstName;
            maReaction.lastName = this.authService.userBundle.user.lastName;
            maReaction.avatarExtension = (<HijiBundle>this.authService.userBundle.data).hijiUser.avatarExtension;
            this.feedback.reactions.push(maReaction);
          } else {
            maReaction.reaction = reaction;
          }

          if (removeReaction) {
            this.feedback.reactions = this.feedback.reactions.filter((r) => r.idUser !== this.idUserLogged);
          }
        });
    }
  }

  getNbReact(reaction: FeedbackReaction) {
    return this.feedback.reactions.filter((r) => r.reaction === reaction).length;
  }

  reacted(reaction: FeedbackReaction) {
    return this.feedback.reactions.find((r) => r.idUser === this.authService.userBundle.user.idUser && r.reaction === reaction) !== undefined;
  }

  openModal(reactions: FeedbackReactionDTO[]) {
    const modal: NgbModalRef = this.modalService.open(FeedbackUserListModalComponent, { centered: true });
    const component: FeedbackUserListModalComponent = modal.componentInstance;
    component.reactions = true;
    component.showVu = false;
    component.users = reactions.map((r) => {
      return {
        firstName: r.firstName,
        lastName: r.lastName,
        fonction: null,
        vu: null,
        idUser: r.idUser,
        avatarExtension: r.avatarExtension,
      };
    });
    modal.result.then(
      (result) => {},
      (err) => {
        L.w(err);
      }
    );
  }
  openModalUsers(users: FeedbackUserDTO[]) {
    const modal: NgbModalRef = this.modalService.open(FeedbackUserListModalComponent, { centered: true });
    const component: FeedbackUserListModalComponent = modal.componentInstance;
    component.users = users.map((r) => {
      return {
        firstName: r.firstName,
        lastName: r.lastName,
        fonction: r.fonction,
        vu: r.viewedDate !== null,
        idUser: r.idUser,
        avatarExtension: r.avatarExtension,
      };
    });
    component.showVu = true;
    modal.result.then(
      (result) => {},
      (err) => {
        L.w(err);
      }
    );
  }
}
